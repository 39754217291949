import {
  sendPostCallToSever,
  sendPatchCallToSever,
  sendDeleteCallToSever,
} from "../Axios/Axios";
import store from "../../redux/store";
import { AuthReducer } from "../../redux/reducers/AuthReducer";
import { ProfileReducer } from "../../redux/reducers/ProfileReducer";
import { MICRO_SERVICES } from "../../config/config";
import { DecryptReduxAuth, modifyPunchData } from "../General/General";
import MessagesList from "../../config/messages.json";
import { ResetCartDetailAPI, UpdateOrderDetailAPI } from "../Cart/Cart";
import { phone } from "phone";
import { OrderReducer } from "../../redux/reducers/OrderReducer";

const AuthActions = AuthReducer ? AuthReducer.actions : "";
const ProfileActions = ProfileReducer.actions;
const OrderActions = OrderReducer.actions;

const LoginAPI = (data) => (dispatch) => {
  console.log("process.env", process.env);
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "users/login",
    data: {
      client: auth.clientId,
      user: data,
    },
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERRORS ARRAY
      let errors = result.data.error.errors;
      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = errors[item][0];
      }
      message = message.trim();
      if (MessagesList.hasOwnProperty(message)) {
        message = MessagesList[message];
        return { generalError: message };
      } else {
        if (
          process.env.REACT_APP_API_URL.indexOf(
            "https://api.order.mixt.com"
          ) === 0
        )
          return { generalError: message };
        else
          return {
            generalError: "Server Crashed, Try Bypass",
            errorDetail: JSON.stringify(result.data),
          };
      }
    } else {
      // GET RESPONSE
      let responseData = result.data.data;
      responseData = modifyPunchData(responseData, "");

      let returnResponse = VerifyUser(responseData, dispatch, "login");
      return returnResponse;
    }
  });
};

const ByPassVerifyUser = () => (dispatch) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);
  // SEND CALL TO SERVER TO VERIFY USER
  return sendPostCallToSever(
    {
      public_key: auth.publicKey,
    },
    MICRO_SERVICES.USERS + "/verifyuser/bypass",
    true,
    0
  ).then((response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      // UPDATE KEY IN REDUX
      dispatch(
        AuthActions.verifyUser({
          accessToken: response.data.access_token,
          serverPrivateKey: response.data.client_key,
          serverPublicKey: response.data.server_key,
          isGuest: false,
        })
      );
      let responseData = modifyPunchData(
        JSON.parse(response.data.userInfo),
        ""
      );
      // UPDATE LOGGED IN USER DATA IN REDUX
      dispatch(ProfileActions.updateUser(responseData));
    }
  });
};

const GuestUser = async (dispatch) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // SEND CALL TO SERVER TO VERIFY USER
  const response = await sendPostCallToSever(
    {
      public_key: auth.publicKey,
    },
    MICRO_SERVICES.USERS + "/verifyuser/guest-login"
  );
  // RETURN ERROR
  if (response.error) {
    return { generalError: response.error };
  } else {
    // UPDATE KEY IN REDUX
    dispatch(
      AuthActions.verifyUser({
        accessToken: response.data.access_token,
        serverPrivateKey: response.data.client_key,
        serverPublicKey: response.data.server_key,
        isGuest: true,
      })
    );
  }
  return "";
};

const VerifyUser = (responseData, dispatch, calledFrom = "") => {
  let userProfileData = _.cloneDeep(responseData);
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // SEND CALL TO SERVER TO VERIFY USER
  return sendPostCallToSever(
    {
      userId: responseData.user_id,
      access_token: responseData.access_token,
      public_key: auth.publicKey,
    },
    MICRO_SERVICES.USERS + "/verifyuser"
  ).then(async (response) => {
    // RETURN ERROR
    if (response.error) {
      return { generalError: response.error };
    } else {
      let isPhoneValid;
      if (response.data.dialCode) {
        isPhoneValid = phone("+" + response.data.dialCode + responseData.phone);
      } else if (userProfileData.temp_country_code) {
        isPhoneValid = phone(
          "+" + userProfileData.temp_country_code + responseData.phone
        );
      } else {
        isPhoneValid = { isValid: false };
      }
      let orderData = _.cloneDeep(store.getState().order.calculate_order_data);
      // UPDATE KEY IN REDUX
      dispatch(
        AuthActions.verifyUser({
          accessToken: response.data.access_token,
          serverPrivateKey: response.data.client_key,
          serverPublicKey: response.data.server_key,
          isGuest: false,
          // hasGuestOrder: orderData.hasOwnProperty("orderType") ? true : false,
        })
      );

      if (
        orderData.hasOwnProperty("orderType") &&
        orderData.items.length === 0
      ) {
        await ResetCartDetailAPI(dispatch);
      }

      userProfileData.country_code = response.data.dialCode;
      if (isPhoneValid.isValid) {
        userProfileData.isFirstLogin = false;
      } else {
        userProfileData.isFirstLogin = true;
      }

      userProfileData.isValidPhoneNumber = isPhoneValid.isValid;
      // UPDATE LOGGED IN USER DATA IN REDUX
      dispatch(ProfileActions.updateUser(userProfileData));

      return { status: 1, route: "/decider" };
    }
  });
};

const RegisterAPI = (data, countryCode) => (dispatch) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "users",
    data: {
      client: auth.clientId,
      user: data,
    },
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERRORS ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = errors[item][0];
      }

      let dateString = "";
      if (message.indexOf("Birthday must be before") === 0) {
        let originalMessage = _.cloneDeep(message);
        message = "Birthday must be before";
        let length = message.length;
        let receivedDate = originalMessage.substring(
          length,
          originalMessage.length
        );
        let receivedDateArray = receivedDate.split("-");
        dateString =
          receivedDateArray[1] +
          "/" +
          receivedDateArray[2] +
          "/" +
          receivedDateArray[0];
      }
      if (MessagesList.hasOwnProperty(message)) {
        message = MessagesList[message];
      }
      if (message.indexOf("{{DATE}}") > -1) {
        message = message.replace("{{DATE}}", dateString);
      }

      // RETURN ERROR
      return { generalError: message };
    } else {
      // GET RESPONSE
      let responseData = result.data.data;
      responseData = modifyPunchData(responseData, countryCode);

      return VerifyUser(responseData, dispatch);
    }
  });
};

const UpdateProfileAPI = (data, id, countryCode) => (dispatch) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;
  // CREATE API DATA OBJECT
  let APIData = {
    method: "put",
    url: process.env.REACT_APP_PUNCHH_URL + "users",
    data: {
      client: auth.clientId,
      user: data,
    },
    access_token: profile.access_token,
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERRORS ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = errors[item][0];
      }

      // CUSTOMIZE PHONE NUMBER MESSAGE AS MENTIONED IN TASK 77 AND SLACK CHAT ON 29 JULY 2022
      // if (message === "Phone has already been taken") {
      //   message = "This phone number is used by another account.";
      // }

      let dateString = "";

      if (message.indexOf("Birthday must be before") === 0) {
        let originalMessage = _.cloneDeep(message);
        message = "Birthday must be before";
        let length = message.length;
        let receivedDate = originalMessage.substring(
          length,
          originalMessage.length
        );
        let receivedDateArray = receivedDate.split("-");
        dateString =
          receivedDateArray[1] +
          "/" +
          receivedDateArray[2] +
          "/" +
          receivedDateArray[0];
      }
      if (MessagesList.hasOwnProperty(message)) {
        message = MessagesList[message];
      }
      if (message.indexOf("{{DATE}}") > -1) {
        message = message.replace("{{DATE}}", dateString);
      }
      // RETURN ERROR
      return { generalError: message };
    } else {
      let responseData = result.data.data;

      let isUpdate = false;
      if (profile.country_code) {
        isUpdate = true;
      }

      UpdateDialCode(id, countryCode, isUpdate);
      // GET RESPONSE

      responseData = modifyPunchData(responseData, countryCode);
      let isPhoneValid = phone("+" + countryCode + responseData.phone);
      responseData.isValidPhoneNumber = isPhoneValid.isValid;
      responseData.country_code = countryCode;
      dispatch(ProfileActions.updateUser(responseData));
      let fullPhoneNumber = countryCode + responseData.phone;
      let orderData = store.getState().order.calculate_order_data;
      if (orderData.hasOwnProperty("orderType")) {
        // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
        let orderItem = _.cloneDeep(orderData);
        orderItem.user_info.phoneNumber = fullPhoneNumber;
        // UPDATE DATA IN REDUX STATE
        dispatch(UpdateOrderDetailAPI(orderItem));
      }
      return {};
    }
  });
};

const UpdateDialCode = (userId, dialCode, isUpdate) => {
  let url, data;
  if (isUpdate) {
    data = {
      dialCode: dialCode,
    };
    url = MICRO_SERVICES.USERS + `/user-info/${userId}`;
    return sendPatchCallToSever(data, url).then((result) => {
      return result.data.data;
    });
  } else {
    data = {
      userId,
      dialCode: dialCode,
    };
    url = MICRO_SERVICES.USERS + `/user-info/`;
    return sendPostCallToSever(data, url).then((result) => {
      return result.data;
    });
  }
};

const ResetPasswordAPI = (data) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "users/forgot_password",
    data: {
      client: auth.clientId,
      user: data,
    },
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = result.data.error.errors.email;
      return { generalError: message };
    } else {
      return {};
    }
  });
};

const RewardAPI = (data) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // GET PROFILE STATE DATA FROM REDUX
  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "coupons",
    data: {
      client: auth.clientId,
      code: data.code,
    },
    access_token: profile.access_token,
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERROR ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = errors[item];
      }

      if (MessagesList.hasOwnProperty(message)) {
        message = MessagesList[message];
        return { generalError: message };
      }

      // RETURN ERROR
      return { generalError: message };
    } else {
      return {};
    }
  });
};

const AddBarCodeAPI = (bar_code) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // GET PROFILE STATE DATA FROM REDUX
  let profile = store.getState().profile;

  // CREATE API DATA OBJECT
  let APIData = {
    method: "post",
    url: process.env.REACT_APP_PUNCHH_URL + "checkins/barcode",
    data: {
      client: auth.clientId,
      bar_code: bar_code,
    },
    access_token: profile.access_token,
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(
    APIData,
    MICRO_SERVICES.USERS + "/punchh-bridge"
  ).then((result) => {
    // RETURN ERROR
    if (!result.data.success) {
      let message = "";

      // GET ERROR ARRAY
      let errors = result.data.error.errors;

      // LOOP OVER ERRORS ARRAY AND RETURN FIRST ONE
      for (const item in errors) {
        message = errors[item];
      }

      if (MessagesList.hasOwnProperty(message)) {
        message = MessagesList[message];
        return { generalError: message };
      }

      // RETURN ERROR
      return { generalError: message };
    } else {
      return { data: result.data.data };
    }
  });
};

const LogoutAPI = () => (dispatch) => {
  // REMOVE ACCESS TOKEN AND SERVER KEY FROM REDUX
  dispatch(AuthActions.Logout({}));

  // EMPTY USER PROFILE
  dispatch(ProfileActions.EmptyProfile({}));

  // EMPTY CART DETAIL
  dispatch(OrderActions.ResetCartDetail());
};

const DeleteAccountAPI = async () => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  let profile = store.getState().profile;
  let body = { client: auth.clientId, user_id: profile.user_id };

  let isAccountDeactivated = await DeactivateAccountAPI(body);
  if (isAccountDeactivated) {
    DeleteDialCode(profile.user_id);
    // CREATE API DATA OBJECT
    let APIData = {
      method: "delete",
      url: process.env.REACT_APP_PUNCHH_PLATFORM_URL + "users",
      data: { ...body, reason: "delete_general" },
      access_token: process.env.REACT_APP_PUNCHH_ADMIN_KEY,
    };

    // SEND CALL TO SERVER TO LOGIN FROM PUNCH
    return sendPostCallToSever(APIData, MICRO_SERVICES.USERS + "/punchh-bridge")
      .then((result) => {
        if (result.data.success) {
          sendPostCallToSever(
            {
              userName: profile.first_name+" "+profile.last_name,
              userEmail: profile.email,
            },
            MICRO_SERVICES.ORDERS + "/menu-order/delete-me"
          );
          return 1;
        } else return 0;
      })
      .catch((error) => {
        return 0;
      });
  } else {
    return 0;
  }
};

const DeactivateAccountAPI = (body) => {
  // GET AUTH STATE DATA FROM REDUX
  let auth = store.getState().auth.data;
  auth = DecryptReduxAuth(auth);

  // CREATE API DATA OBJECT
  let APIData = {
    method: "delete",
    url: process.env.REACT_APP_PUNCHH_PLATFORM_URL + "users/deactivate",
    data: { ...body },
    access_token: process.env.REACT_APP_PUNCHH_ADMIN_KEY,
  };

  // SEND CALL TO SERVER TO LOGIN FROM PUNCH
  return sendPostCallToSever(APIData, MICRO_SERVICES.USERS + "/punchh-bridge")
    .then((result) => {
      if (result.data.success) return 1;
      else return 0;
    })
    .catch((error) => {
      return 0;
    });
};

const EmptyAccessTokenAPI = () => (dispatch) => {
  // REMOVE ACCESS TOKEN AND SERVER KEY FROM REDUX
  dispatch(AuthActions.Logout({}));
};

const DeleteDialCode = (userId) => {
  let url = `${MICRO_SERVICES.USERS}/user-info/${userId}`;
  return sendDeleteCallToSever(url).then((result) => {
    return result.data.data;
  });
};

export {
  LoginAPI,
  LogoutAPI,
  RegisterAPI,
  UpdateProfileAPI,
  ResetPasswordAPI,
  RewardAPI,
  VerifyUser,
  ByPassVerifyUser,
  UpdateDialCode,
  EmptyAccessTokenAPI,
  DeleteAccountAPI,
  AddBarCodeAPI,
  DeleteDialCode,
  GuestUser,
};
