import React, { useEffect, useState } from "react";
import Text from "../../components/GeneralComponents/Text";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import TipElement from "./TipElement";
import { TIP_PERCENTAGES_LIST } from "../../config/config";
import RightArrow from "../../assets/img/MenuDetailRight.svg";
import { CircularProgress } from "@material-ui/core";
import CardImage from "./CardImage";

function Checkout({
  totalPrice,
  placeOrder,
  setDisplayType,
  orderDestination,
  selectedCardIndex,
  updatePaymentInfo,
  loader,
  setLoader,
}) {
  // GET MENU LOCATION DETAILS FROM REDUX STATE
  const orderData = useSelector((state) => state.order.calculate_order_data);
  const cardsData = useSelector((state) => state.profile.cards_list);
  // LOCAL STATE
  const [shownTipsList] = useState(TIP_PERCENTAGES_LIST);
  const [disableButton, setDisableButton] = useState(false);
  const [apiData, setApiData] = useState({});
  const [callApi, setCallApi] = useState(false);
  const [hasCustomPercentageFocussed, setHasCustomPercentageFocussed] =
    useState(false);
  const [tipPercentage, setTipPercentage] = useState(
    orderData.payment_info.tipName === "notSet"
      ? 15
      : orderData.payment_info.tipName === "Custom" ||
        orderData.payment_info.tipName === "None"
      ? orderData.payment_info.tipName
      : parseInt(orderData.payment_info.tipName)
  );
  // const [tipAmount, setTipAmount] = useState(0);
  const [tipAmount, setTipAmount] = useState(
    orderData.payment_info.tipName === "notSet"
      ? "0"
      : orderData.payment_info.tipAmount
  );

  useEffect(() => {
    let percentage = tipPercentage;
    totalPrice -= orderData.payment_info.tipAmount;
    let amount = parseFloat(((totalPrice / 100) * tipPercentage).toFixed(2));

    if (amount < 0) {
      amount = 0;
    }

    if (tipPercentage === -1) {
      amount = tipAmount
        ? parseFloat(tipAmount)
        : orderData.payment_info.tipAmount;
    }
    console.log("tipPercentage,amount", tipPercentage, amount, orderData);
    if (
      !orderData.payment_info.hasOwnProperty("idPaymentCard") ||
      orderData.payment_info.idPaymentCard === 0
    ) {
      let selectedCardId =
        cardsData.length > 0 ? cardsData[selectedCardIndex].idPaymentCard : 0;

      updatePaymentInfo(percentage, amount, selectedCardId);
    } else {
      updatePaymentInfo(percentage, amount);
    }
  }, [tipPercentage]);

  useEffect(() => {
    if (callApi) {
      updatePaymentInfo(apiData.percentage, parseFloat(apiData.amount));
      setDisableButton(false);
      setCallApi(false);
    }
  }, [callApi]);

  const setTipAmountVal = (tip, isBlur) => {
    setDisableButton(true);
    setTipAmount(tip);
    let percentage = -1;
    let amount = 0;

    if (tip !== "" && tip >= 0) {
      amount = tip;
    }

    amount = parseFloat(amount);
    console.log("isBlur", isBlur);
    if (isBlur) {
      // updatePaymentInfo(percentage, parseFloat(amount));
      // setDisableButton(false);
      setApiData({ percentage, amount: parseFloat(amount) });
      setCallApi(true);
    }
  };

  const checkoutSubmit = () => {
    if (!disableButton && !loader) {
      setLoader(true);
      placeOrder();
    }
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      checkoutSubmit();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [orderData]);
  const [debouncedCallApi] = useState(() => _.debounce(setTipAmountVal, 1000));
  return (
    <>
      <h4 className="text-left BrandenGrotesqueBold font-size-1_3 text-grey1 border-bottom-1 pb-2">
        Payment Details
      </h4>

      {/* PAYMENT CARDS LIST OR ADD PAYMENT METHOD TEXT */}
      <div className="border-bottom-1 pb-2">
        <div
          className="d-flex align-items-center justify-content-between cursor-pointer"
          onClick={() =>
            setDisplayType(cardsData && cardsData.length > 0 ? 2 : 3)
          }
        >
          <div>
            <CardImage
              type={
                cardsData && cardsData.length > 0
                  ? cardsData[selectedCardIndex].cardType.toLowerCase()
                  : ""
              }
            />
            {cardsData && cardsData.length === 0 ? (
              <span className="px-3 BrownSTDBold font-size-0_9 text-grey1 mb-0">
                Add Payment Method
              </span>
            ) : (
              <span className="px-3 BrownSTDBold font-size-0_9 text-grey1 mb-0">
                <span>{`$(${totalPrice})`}</span>
                <span className="big_dot"></span>
                <span>{`${cardsData[selectedCardIndex].lastFourDigits}`}</span>
              </span>
            )}
          </div>
          <img src={RightArrow} />
        </div>
      </div>

      {/* TIP  */}
      <div className="border-bottom-1 pt-3 pb-2">
        <div className="d-flex flex-column">
          <span className="BrandenGrotesqueBold font-size-1_3 text-grey1">
            {/* NOVEMBER 7 SKYPE (Sir Irfan)
            destinationEnum is 0 === Pickup, 1 === Mixt Delivery, 2 === Door dash Delivery */}
            {orderData.orderType === "pickup"
              ? "Add a Tip"
              : "Tip for Delivery"}
          </span>
          <span className="BrownSTDRegular font-size-0_9 text-grey1">
            {orderData.orderType === "pickup" || orderDestination === 1
              ? "Our teams greatly appreciate it!"
              : "Our delivery partner requires that 100% of this tip goes to their driver. You are welcome to select any tip amount you want."}
          </span>
        </div>
      </div>

      {/* TIP AMOUNT  */}
      <div className="py-2">
        <div className="d-flex flex-column">
          <span className="BrownSTDRegular font-size-0_9 text-grey1 pb-2">
            Please specify tip
          </span>
          <div className="d-flex justify-content-between">
            {shownTipsList.map((tipItem, index) => (
              <TipElement
                key={index}
                title={tipItem.key}
                value={tipItem.value}
                tipPercentage={tipPercentage}
                setTipPercentage={setTipPercentage}
                orderData={orderData}
              />
            ))}
          </div>
        </div>
      </div>

      {tipPercentage === -1 && (
        <Text
          id={"percentageText"}
          hasFocus={()=>setHasCustomPercentageFocussed(true)}
          hasBlur={()=>setHasCustomPercentageFocussed(false)}
          label={"Enter custom tip in dollars"}
          name={"tipAmount"}
          value={tipAmount}
          type={"number"}
          setValue={(e) => {
            setTipAmountVal(e, false);
            debouncedCallApi(e, true);
          }}
          classes={"font-size-1"}
        />
      )}

      {/* PLACE ORDER BUTTON */}
      <div className="text-center">
        <Button
          onClick={() => {
            checkoutSubmit();
          }}
          disabled={loader || disableButton}
          variant=""
          className={`btn-half w-auto text-uppercase BrandenGrotesqueBold bg-brown2 border-brown2 border-0 rounded-full font-size-1_1 text-white px-3 line-height-1 mb-0 default-letter-spacing mt-3`}
        >
          {loader ? (
            <CircularProgress size={24} />
          ) : (
            `place order - $${totalPrice}`
          )}
        </Button>
      </div>

      {/* TERMS AND CONDITIONS TEXT */}
      <p className="BrownSTDRegular font-size-0_8 text-grey7 my-3 py-2">
        By placing this order you accept our{" "}
        <span
          onClick={() =>
            window.open("https://www.mixt.com/terms-of-use", "_blank")
          }
          className="BrownSTDBold cursor-pointer"
        >
          Terms and Conditions
        </span>{" "}
        and{" "}
        <span
          onClick={() =>
            window.open("https://www.mixt.com/privacy-policy", "_blank")
          }
          className="BrownSTDBold cursor-pointer"
        >
          Privacy Policy
        </span>
      </p>

      {/* ALLERGEN DISCLAIMER TEXT */}
      <h4 className="BrownSTDBold font-size-0_8 text-grey7 mb-0">
        Allergen Disclaimer
      </h4>
      <p className="BrownSTDRegular text-grey7 mb-4 text-left">
        At Mixt we use all major allergens in our kitchens, so we cannot
        guarantee that our food is completely free of any allergen. If you have
        a severe allergy, we recommend not ordering from our restaurant.
      </p>

      {/* DISCLAIMER TEXT */}
      <div className="mt-4">
        <h4 className="BrownSTDBold font-size-0_8 text-grey7 mb-0">
          Delivery Provider
        </h4>
        <p className="BrownSTDRegular text-grey7 mb-3 text-left">
          You authorize Mixt to share your contact and location data with our
          delivery service provider.
        </p>
        <h4 className="BrownSTDBold font-size-0_8 text-grey7 mb-0">
          Cancellation Policy
        </h4>
        <p className="BrownSTDRegular text-grey7 text-left">
          Same day orders cannot be cancelled or changed.
        </p>
      </div>
    </>
  );
}

export default Checkout;
