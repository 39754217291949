import React, { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import PackageCustomizationItem from "./PackageCustomizationItem";
import LeftArrow from "../../assets/img/LeftArrow.jpg";
import RightArrow from "../../assets/img/RightArrow.jpg";
import PackageItemComplete from "../../assets/img/PackageItemComplete.svg";
import PackageItemInComplete from "../../assets/img/PackageItemInComplete.png";

function PackageCustomizationGroup({
  modifier,
  ingredients,
  setIngredients,
  errorImg,
  modifiersDescription,
  categoryCustomType,
}) {
  // CREATE REF FOR DRAGGABLE
  const ref = useRef();
  const { events } = useDraggable(ref);

  const MoveLeft = (id) => {
    document.getElementById(id).classList.add("scroll-smooth");
    document.getElementById(id).scrollLeft -= 315;
    document.getElementById(id).classList.remove("scroll-smooth");
  };

  const MoveRight = (id) => {
    document.getElementById(id).classList.add("scroll-smooth");
    document.getElementById(id).scrollLeft += 315;
    document.getElementById(id).classList.remove("scroll-smooth");
  };

  return (
    <div
      className={`w-100 justify-content-center ${
        categoryCustomType === 7 ? "py-1" : "py-4"
      }`}
      key={modifier.idItemModifiers}
    >
      {/* <div className="d-flex justify-content-center px-3 mx-auto px-sm-4 mx-sm-5 flex-column"> */}
      <div className="d-flex justify-content-center px-3 mx-auto px-sm-4 mx-sm-5 mt-1 mt-sm-0 flex-column">
        <div className="d-flex mb-2">
          <span className="me-2" id={"modifier_" + modifier.brinkId}>
            {/* IF IM DONE BUTTON CLICKED OR COUNTER KEY EXIST FOR LOOPED MODIFIER */}
            {!errorImg ? (
              ingredients.hasOwnProperty("modifiersCounter") &&
              ingredients.modifiersCounter.hasOwnProperty(modifier.brinkId) ? (
                <>
                  {/* IF IM DONE BUTTON NOT PRESSED AND MODIFIER COUNTER EQUAL OR GREATER THAN TO MODIFIER MINIMUM VALUE */}
                  {!errorImg &&
                  ingredients.modifiersCounter[modifier.brinkId] >=
                    modifier.minimum ? (
                    <img
                      className="package-customization-icon"
                      src={PackageItemComplete}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )
            ) : errorImg && modifier.minimum > 0 ? (
              <>
                {!ingredients.hasOwnProperty("modifiersCounter") ||
                !ingredients.modifiersCounter.hasOwnProperty(
                  modifier.brinkId
                ) ||
                ingredients.modifiersCounter[modifier.brinkId] <
                  modifier.minimum ? (
                  <img
                    className="package-customization-icon"
                    src={PackageItemInComplete}
                  />
                ) : (
                  <img
                    className="package-customization-icon"
                    src={PackageItemComplete}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </span>
          <span className="BrownSTDBold font-size-1 text-grey4 pt-1">
            Select {modifier.displayName} -{" "}
            {ingredients.hasOwnProperty("modifiersCounter") &&
            ingredients.modifiersCounter.hasOwnProperty(modifier.brinkId)
              ? ingredients.modifiersCounter[modifier.brinkId]
              : 0}{" "}
            of {modifier.minimum} {categoryCustomType === 7 ? "or more" : ""}{" "}
            selected
          </span>
        </div>
        <div className="d-flex align-items-center position-relative">
          <img
            src={LeftArrow}
            className="cursor-pointer d-md-block d-none position-absolute mix-blend-mode package-customization-arrows"
            style={{ left: "-1.3%" }}
            onClick={() => MoveLeft("scrollBar_" + modifier.idItemModifiers)}
          />
          <div
            id={"scrollBar_" + modifier.idItemModifiers}
            className="d-flex text-center overflow-x-scroll scrollbar-hide mix-blend-mode"
            {...events}
            ref={ref}
          >
            {modifier.modifierGroupItems.map((groupItem) => {
              let ingredientDescription = "";
              if (
                modifiersDescription.hasOwnProperty(
                  groupItem.menuItem.itemIdBrink
                )
              ) {
                ingredientDescription =
                  modifiersDescription[groupItem.menuItem.itemIdBrink]
                    .categoryMenuItemDescription;
              }
              return (
                <PackageCustomizationItem
                  groupItem={groupItem}
                  ingredientName={ingredientDescription}
                  modifier={modifier}
                  key={groupItem.idModifierGroupItems}
                  setIngredients={setIngredients}
                  ingredients={ingredients}
                />
              );
            })}
          </div>
          <img
            src={RightArrow}
            className="cursor-pointer d-md-block d-none position-absolute package-customization-arrows"
            style={{ right: "-1.5%" }}
            onClick={() => MoveRight("scrollBar_" + modifier.idItemModifiers)}
          />
        </div>
      </div>
    </div>
  );
}

export default PackageCustomizationGroup;
