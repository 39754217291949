import React, { useEffect, useState } from "react";
import { GetAllCampaigns } from "../actions/Campaign/Campaign";
import Carousel from "react-multi-carousel";
import {
  CreateGoogleAnalyticsAPI,
  getImageUrl,
} from "../actions/General/General";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import LastOrderStatus from "../components/Navbars/LastOrderStatus";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1200, min: 480 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

function Campaigns() {
  const history = useHistory();
  const [data, setData] = useState({
    campaigns: [],
    favoriteOrders: [],
    lastOrder: [],
  });
  const [loader, setLoader] = useState(false);

  // GET TRACK ORDER ID
  let track_order_id = useSelector((state) => state.order.track_order_id);
  const profile = useSelector((state) => state.profile);

  useEffect(async () => {
    if (track_order_id) {
      history.push("/track/" + track_order_id);
    } else {
      CreateGoogleAnalyticsAPI("CampaignView", {});
      setLoader(true);
      let result = await GetAllCampaigns();
      setData(result.data);
      setLoader(false);
      console.log("navigator", navigator.userAgent, navigator);

      console.log(document.getElementsByClassName("dashboardImage"));
      if (document.getElementsByClassName("dashboardImage")) {
        let allElements = document.getElementsByClassName("dashboardImage");
        // .map((singleImageClass) => {
        //   if (userAgent.indexOf("Windows") !== -1)
        //     singleImageClass.classList.add("isWin");
        //   else singleImageClass.classList.add("isMac");
        // });
        for (let index = 0; index < allElements.length; index++) {
          const element = allElements[index];
          if (navigator.userAgent.indexOf("Windows") !== -1)
            element.classList.add("isWin");
          else element.classList.add("isMac");
        }
      }
    }
  }, []);

  return (
    <>
      {!loader ? (
        <>
          <LastOrderStatus
            user_name={profile.first_name}
            className={"d-flex d-md-none"}
          />
          <Carousel
            responsive={responsive}
            arrows={false}
            showDots={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            className={"campaign-carousel p-0"}
          >
            {data &&
              data.favoriteOrders &&
              data.favoriteOrders.map((favorite) => {
                let webImage = getImageUrl(favorite, "fullFavImageUrlDesktop");
                let MobileImage = getImageUrl(
                  favorite,
                  "fullFavImageUrlMobile"
                );
                return (
                  <div
                    className="d-flex flex-column text-center"
                    key={"favorite" + favorite.idFavoriteOrderItem}
                  >
                    <div className="position-relative">
                      <div className="position-absolute cmt-30 mt-md-2 d-flex flex-column favorite-item-detail">
                        <div className="d-flex justify-content-center">
                          <span className="text-uppercase BrandenGrotesqueRegular text-brown1 line-height-1">
                            From your
                          </span>
                          <span className="text-uppercase BrandenGrotesqueBold text-brown1 line-height-1">
                            Favorites
                          </span>
                        </div>
                        <span className="BrownSTDRegular text-grey5 pt-1">
                          Tap to add to cart
                        </span>
                      </div>
                      <div
                        onClick={() =>
                          history.push(
                            "locations?id=" + favorite.idFavoriteOrderItem
                          )
                        }
                        className={
                          "d-none d-sm-block p-0 dashboardImage cursor-pointer"
                        }
                        style={{ backgroundImage: `url(${webImage})` }}
                      ></div>
                      <div
                        onClick={() =>
                          history.push(
                            "locations?id=" + favorite.idFavoriteOrderItem
                          )
                        }
                        className={
                          "d-block d-sm-none p-0 dashboardImage cursor-pointer cmt-sm-60"
                        }
                        style={{ backgroundImage: `url(${MobileImage})` }}
                      ></div>
                      <div className="position-absolute text-center text-md-left dashboard-image-text">
                        <span
                          className="d-none d-md-block text-uppercase BrandenGrotesqueBold text-grey4"
                          style={{
                            fontSize:
                              "clamp(24px," +
                              359 / favorite.itemName.length +
                              "px,48px)",
                          }}
                        >
                          {favorite.itemName}
                        </span>
                        <span
                          className="d-md-none text-uppercase BrandenGrotesqueBold text-grey4"
                          style={{
                            fontSize:
                              "clamp(16px," +
                              359 / favorite.itemName.length +
                              "px,32px)",
                          }}
                        >
                          {favorite.itemName}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            {data &&
              data.campaigns &&
              data.campaigns.map((campaign) => {
                return (
                  <div
                    className="d-flex flex-column text-center"
                    key={"campaign" + campaign.idCampaign}
                  >
                    <div
                      className="position-relative"
                      onClick={() =>
                        history.push("/locations?cid=" + campaign.itemIdBrink)
                      }
                    >
                      <div
                        className={
                          "d-none d-sm-block p-0 dashboardImage cursor-pointer"
                        }
                        style={{
                          backgroundImage: `url(${campaign.fullPathWebsitePhoto})`,
                        }}
                      ></div>
                      <div
                        className={
                          "d-block d-sm-none p-0 dashboardImage cursor-pointer"
                        }
                        style={{
                          backgroundImage: `url(${campaign.fullPathAppPhoto})`,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </>
      ) : (
        <CircularProgress size={24} />
      )}
    </>
  );
}

export default Campaigns;
