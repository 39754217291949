import _ from "lodash";
import moment from "moment";
import { MENU_ITEM_TYPE, MICRO_SERVICES } from "../../config/config";
import { GetMenuItemDetailForReorderAPI } from "../Menu/Menu";
import { GetFavoriteItemDetailAPI } from "../Orders/Order";
import {
  calculateOrderPrice,
  createOrderItemsData,
  populateDefaultList,
  rePopulateIngredientsFromOrderData,
  updateModifiersCounter,
} from "../../views/Menu/MenuFunctions";
import store from "../../redux/store";
import { analytics } from "../../config/firebase-config";
import { sendPostCallToSever } from "../Axios/Axios";

var CryptoJS = require("crypto-js");

// REGULAR EXPRESSION FOR VALID EMAIL
const isValidEmail = (email) =>
  /\S+@\S+\.\S+/.test(email) ? "" : "Please enter a valid email address";

// VALID PASSWORD LENGTH CHECK
const isValidPassword = (password) =>
  password.length >= 8 ? "" : "Password must have atleast 8 characters";

// CUSTOM FIELDS VALIDATION FOR FIELDS EMAIL AND PASSWORD FOR NOW
const FieldsValidation = (value, name) => {
  if (name === "EMAIL") {
    return isValidEmail(value);
  } else if (name === "PASSWORD") {
    return isValidPassword(value);
  } else {
    return "";
  }
};

// CONVERT UNIX TIME STAMP TO ANOTHER DAY TIME
const convertUnixTimeStampDate = (previousTime, newTime, timezone) => {
  // CREATE TIME WITH NEW TIME
  let currentStartTime = moment.tz(newTime, timezone);

  // UPDATE CREATED DATE TIME OBJECT WITH PREVIOUS START TIME
  currentStartTime.set({
    hour: moment.tz(previousTime, timezone).hour(),
    minute: moment.tz(previousTime, timezone).minute(),
    second: moment.tz(previousTime, timezone).second(),
  });

  // CREATE NEW START TIME
  let time = moment.tz(currentStartTime, timezone).unix();
  return time;
};

// GET BUSINESS AND CUT OFF TIMING
const getBusinessAndCutOffTiming = (
  locationType,
  selectedLocation,
  currentDay
) => {
  let businessTimings = null;
  let cutoffTimings = null;

  // GET BUSINESS AND CUT OFF TIMING OF LOCATION ACCORDING TO PICK UP OR DELIVERY
  if (locationType === "pickup") {
    businessTimings = _.cloneDeep(selectedLocation.businessTimings);
    cutoffTimings = _.cloneDeep(selectedLocation.cutoffTimings);
  } else if (
    selectedLocation.hasOwnProperty("doorDashLocation") &&
    selectedLocation.doorDashLocation
  ) {
    businessTimings = _.cloneDeep(
      selectedLocation.doorDashLocation.businessTimings
    );
    cutoffTimings = _.cloneDeep(
      selectedLocation.doorDashLocation.cutoffTimings
    );
  } else if (
    selectedLocation.hasOwnProperty("mixtLocation") &&
    selectedLocation.mixtLocation
  ) {
    businessTimings = _.cloneDeep(
      selectedLocation.mixtLocation.businessTimings
    );
    cutoffTimings = _.cloneDeep(selectedLocation.mixtLocation.cutoffTimings);
  }
  businessTimings.unshift(businessTimings.pop());
  cutoffTimings.unshift(cutoffTimings.pop());

  // GET THE SELECTED DAY TIMING
  businessTimings = businessTimings[currentDay];
  cutoffTimings = cutoffTimings[currentDay];
  // cutoffTimings = null;

  // RETURN TIMING FOR SELECTED DAY
  return { businessTimings, cutoffTimings };
};

// GET TIME AND CLOSED STATUS
const getTimeAndClosedStatus = (
  locationType,
  businessTimings,
  cutoffTimings,
  timezone,
  date = "",
  exceptionTimes
) => {
  let startTime = 0;
  let endTime = 0;
  // GET CURRENT TIME
  let currentTime = moment.tz(timezone).unix();
  if (date !== "") {
    currentTime = moment.tz(date, timezone).unix();
  }
  if (locationType === "pickup") {
    // IF PICK UP IS SET, THEN START TIMING FROM PICKUP CUT OFF TIME TO CLOSING TIME
    startTime =
      cutoffTimings && cutoffTimings.pickupCutoffTime
        ? cutoffTimings.pickupCutoffTime
        : businessTimings.openTime;
    // startTime = businessTimings.openTime;
    endTime = businessTimings.closeTime;
  } else {
    // IF DELIVERY IS SET, THEN START TIMING FROM OPEN TIME TO DELIVERY CUT OFF TIME
    startTime = businessTimings.openTime;
    // endTime = businessTimings.closeTime;
    endTime =
      cutoffTimings && cutoffTimings.deliveryCutoffTime
        ? cutoffTimings.deliveryCutoffTime
        : businessTimings.closeTime;
  }

  // CONVERT OPEN TIME TO TODAY OPEN TIME
  startTime = convertUnixTimeStampDate(
    startTime * 1000,
    currentTime * 1000,
    timezone
  );

  // CONVERT CLOSE TIME TO TODAY CLOSE TIME
  endTime = convertUnixTimeStampDate(
    endTime * 1000,
    currentTime * 1000,
    timezone
  );

  let isClosed = false;

  if (exceptionTimes && exceptionTimes.length > 0) {
    for (let i = 0; i < exceptionTimes.length; i++) {
      let exceptionItem = exceptionTimes[i];
      let exceptionDate = exceptionItem.exceptionDate;

      // CALCULATE TIMES
      // let currentDayInSeconds = Math.floor(currentTime / 1000);
      let currentDayInSeconds = Math.floor(currentTime);
      let closureDate = moment
        .tz(exceptionDate.closureDate * 1000, timezone)
        .unix();
      let dayStartTime = moment
        .tz(currentTime * 1000, timezone)
        .startOf("d")
        .unix();
      let dayEndTime = moment
        .tz(currentTime * 1000, timezone)
        .endOf("d")
        .unix();

      // IF LOCATION IS CLOSED
      if (!exceptionDate.isOpen) {
        if (
          closureDate >= dayStartTime &&
          closureDate <= dayEndTime &&
          currentDayInSeconds >= dayStartTime &&
          currentDayInSeconds <= dayEndTime
        ) {
          startTime = 0;
        }
      } else {
        // IF START TIME IS IN RANGE THEN UPDATE START TIME
        // ELSE IF END TIME IS IN RANGE THEN UPDATE END TIME
        let overrideOpenDateTime =
          exceptionDate.overrideOpenDateTime === 0
            ? exceptionDate.overrideOpenDateTime
            : convertUnixTimeStampDate(
                exceptionDate.overrideOpenDateTime * 1000,
                closureDate * 1000,
                timezone
              );
        let overrideCloseDateTime =
          exceptionDate.overrideCloseDateTime === 0
            ? exceptionDate.overrideCloseDateTime
            : convertUnixTimeStampDate(
                exceptionDate.overrideCloseDateTime * 1000,
                closureDate * 1000,
                timezone
              );
        if (
          overrideOpenDateTime !== 0 &&
          overrideOpenDateTime >= dayStartTime &&
          overrideOpenDateTime <= dayEndTime &&
          currentDayInSeconds >= dayStartTime &&
          currentDayInSeconds <= dayEndTime
        ) {
          startTime = overrideOpenDateTime;
        }

        if (
          overrideCloseDateTime !== 0 &&
          overrideCloseDateTime >= dayStartTime &&
          overrideCloseDateTime <= dayEndTime &&
          currentDayInSeconds >= dayStartTime &&
          currentDayInSeconds <= dayEndTime
        ) {
          endTime = overrideCloseDateTime;
        }
      }
    }
  }
  // CHECK IF IT IS CLOSED OR CURRENT TIME IS OUT OF TIME RANGE, SET CLOSED TO TRUE
  if (
    // businessTimings.openTime === 0 ||
    startTime === 0 ||
    currentTime < startTime ||
    currentTime > endTime
  ) {
    isClosed = true;
  }
  return { startTime, endTime, isClosed };
};

// MODIFY PUNCHH RECEIVED DATA TO OWN FORMAT
const modifyPunchData = (punchhData, countryCode) => {
  let updatedData = {
    address_line1: punchhData.user.address,
    avatar_remote_url: punchhData.user.avatar_remote_url,
    birthday: punchhData.user.birthday,
    city: punchhData.user.city,
    created_at: punchhData.user.created_at,
    email: punchhData.user.email,
    email_verified: punchhData.user.email_verified,
    fb_uid: punchhData.user.fb_uid,
    first_name: punchhData.user.first_name,
    gender: punchhData.user.gender,
    id: punchhData.user.user_id,
    last_name: punchhData.user.last_name,
    state: punchhData.user.state,
    zip_code: punchhData.user.zip_code,
    test_user: false,
    allow_multiple: false,
    authentication_token: punchhData.access_token.refresh_token,
    referral_code: punchhData.user.referral_code,
    referral_path: punchhData.user.referral_path,
    title: punchhData.user.title,
    user_code: punchhData.user.user_code,
    user_id: punchhData.user.user_id,
    phone: punchhData.user.phone,
    // country_code: punchhData.user.address,
    country_code: "",
    temp_country_code: countryCode,
    allow_push_notifications: false,
    access_token: punchhData.access_token.token,
  };
  return updatedData;
};

const EncryptReduxAuth = (data) => {
  // CONVERT DATA TO BASE 64 STRING
  let base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

  // CONVERT THE DEFAULT ACCESS TOKEN TO BASE64
  const base64Token = Buffer.from(
    process.env.REACT_APP_DEFAULT_ACCESS_TOKEN
  ).toString("base64");

  // ENCRYPT DATA WITH ENCRYPTED OBJECT
  return CryptoJS.AES.encrypt(base64Data, base64Token).toString();
};

const DecryptReduxAuth = (data) => {
  // CONVERT THE DEFAULT ACCESS TOKEN TO BASE64
  const base64Token = Buffer.from(
    process.env.REACT_APP_DEFAULT_ACCESS_TOKEN
  ).toString("base64");

  // ENCRYPT DATA WITH DECRYPTED OBJECT
  let decryptedData = CryptoJS.AES.decrypt(data, base64Token);
  decryptedData = decryptedData.toString(CryptoJS.enc.Utf8);

  // CONVERT BASE64 STRING TO OBJECT
  return JSON.parse(Buffer.from(decryptedData, "base64").toString("ascii"));
};

// GET IMAGE FROM OBJECT AND RETURN URL
const getImageUrl = (item, name = "") => {
  if (name === "")
    return item &&
      item.hasOwnProperty("itemphoto") &&
      item.itemphoto &&
      item.itemphoto.hasOwnProperty("imageUrl") &&
      item.itemphoto.imageUrl
      ? item.itemphoto.imageUrl
      : "";
  else if (name === "fullFavImageUrlDesktop")
    return item &&
      item.hasOwnProperty("itemphoto") &&
      item.itemphoto &&
      item.itemphoto.hasOwnProperty("fullFavImageUrlDesktop") &&
      item.itemphoto.fullFavImageUrlDesktop
      ? item.itemphoto.fullFavImageUrlDesktop
      : "";
  else
    return item &&
      item.hasOwnProperty("itemphoto") &&
      item.itemphoto &&
      item.itemphoto.hasOwnProperty("fullFavImageUrlMobile") &&
      item.itemphoto.fullFavImageUrlMobile
      ? item.itemphoto.fullFavImageUrlMobile
      : "";
};

// CALCULATE ORDER ITEMS TOTAL AMOUNT
const CalculateTotalAmountOfOrder = (data) => {
  let orderItems = data.items;
  let amount = 0;
  orderItems.map((item) => {
    let itemAmount = item.price;
    item.modifierItems.map((modifierItem) => {
      itemAmount += modifierItem.itemPrice;
    });
    itemAmount *= item.quantity;
    amount += itemAmount;
  });

  let payment_info = {
    totalAmount: parseFloat(amount.toFixed(2)),
    tipName:
      data && data.payment_info && data.payment_info.tipName
        ? data.payment_info.tipName
        : "notSet",
    tipAmount:
      data && data.payment_info && data.payment_info.tipAmount
        ? data.payment_info.tipAmount
        : parseInt(0),
    subTotalAmount: 1,
    idPaymentCard:
      data && data.payment_info && data.payment_info.idPaymentCard
        ? data.payment_info.idPaymentCard
        : 0,
  };

  return payment_info;
};

const GetOrderItemsListToSeparateArrays = (items) => {
  let includedProteinsList = [];
  let dressingsList = [];
  let addedList = [];
  let RemovedList = [];
  let containerList = [];
  items.length > 0 &&
    items.map((singleItem, index) => {
      if (singleItem) {
        let included_protein_ingredients = [];
        singleItem.modifierItems.map((item) => {
          if (item.modifierOfType === MENU_ITEM_TYPE["included_protein"]) {
            let itemName = item.itemName;
            if (item.itemPrice > 0) {
              itemName += " ($" + item.itemPrice + ")";
            }
            included_protein_ingredients.push(itemName);
          }
        });
        included_protein_ingredients = included_protein_ingredients
          .filter(Boolean)
          .join(", ");

        // DRESSING INGREDIENTS
        let dressing_ingredients = singleItem.modifierItems
          .map((item) =>
            item.modifierOfType === MENU_ITEM_TYPE["dressing"]
              ? `<p
              key={"dressing" + ${index}}
              class="BrownSTDRegular font-size-0_9 text-grey1 text-capitalize mb-0"
            >
                <span class="BrownSTDBold">${item.itemName}: </span>
              ${item.modifierCodeName}
              </p>`
              : null
          )
          .filter(Boolean);

        // CONTAINER INGREDIENTS
        let container_ingredients = singleItem.modifierItems
          .map((item) =>
            item.modifierOfType === MENU_ITEM_TYPE["container"]
              ? item.itemPrice > 0
                ? item.itemName + " ($" + item.itemPrice + ")"
                : item.itemName
              : null
          )
          .filter(Boolean)
          .join(", ");

        // NORMAL INGREDIENTS
        let normal_ingredients = singleItem.modifierItems
          .map((item) =>
            item.modifierOfType === MENU_ITEM_TYPE["other"]
              ? item.itemPrice > 0
                ? item.itemName + " ($" + item.itemPrice + ")"
                : item.itemName
              : null
          )
          .filter(Boolean);
        // .join(", ");

        // REMOVED INGREDIENTS
        let removed_ingredients = singleItem.modifierItems
          .map((item) => (item.isRemoved === true ? item.itemName : null))
          .filter(Boolean)
          .join(", ");

        let itemsCount = _.countBy(normal_ingredients);
        let addedItemsList = [];
        for (const [key, val] of Object.entries(itemsCount)) {
          if (val > 1) {
            if (key.indexOf(")") > -1) {
              addedItemsList.push(key.replace(")", " x " + val + ")"));
            } else {
              addedItemsList.push(key + "(" + val + ")");
            }
          } else {
            addedItemsList.push(key);
          }
        }
        includedProteinsList.push(included_protein_ingredients);
        dressingsList.push(dressing_ingredients);
        containerList.push(container_ingredients);
        addedList.push(addedItemsList.join(", "));
        RemovedList.push(removed_ingredients);
      }
    });
  return {
    includedProteinsList,
    dressingsList,
    containerList,
    addedList,
    RemovedList,
  };
};

const updateCartRewards = (
  reward_id,
  reward_name,
  reward_amount,
  rewardType,
  orderData,
  appliedReward,
  isDisplayError = false
) => {
  // CLONE ORDER DATA FROM REDUX STATE TO LOCAL VARIABLE FOR ORDER DATA
  let orderItem = _.cloneDeep(orderData);

  // IF SELECTED REWARD IS ALREADY SELECTED THEN REMOVE REWARD KEY
  if (appliedReward === reward_id) {
    delete orderItem.discounts;
  } else {
    orderItem.discounts = [
      {
        name: reward_name,
        type: "punchh",
        rewardId: reward_id.toString(),
        amount: parseFloat(reward_amount),
        rewardType: rewardType,
        isDisplayError: isDisplayError,
      },
    ];
  }
  return orderItem;
};

const createMenuRequestData = (location, type) => {
  // CREATE MENU DETAIL CALL DATA

  // let getMenuDetailAPIObj = {
  //   type: "single",
  //   locationId1:
  //     type === "favorite"
  //       ? location.mixtLocationId
  //         ? parseInt(location.mixtLocationId)
  //         : null
  //       : location.mixtLocationId
  //       ? parseInt(location.mixtLocationId)
  //       : null,
  // };

  let getMenuDetailAPIObj = {
    type: "single",
    // locationId1: location.mixtLocationId ? parseInt(location.mixtLocationId) : null,
  };
  // CHECK IF LOCATION HAS DOOR DASH LOCATION ID THEN ADD AND MARK TYPE AS COMMON
  if (type === "favorite") {
    if (
      location.hasOwnProperty("doordashLocationId") &&
      location.doordashLocationId &&
      location.mixtLocationId
    ) {
      getMenuDetailAPIObj.locationId1 = parseInt(location.mixtLocationId);
      getMenuDetailAPIObj.locationId2 = parseInt(location.doordashLocationId);
      getMenuDetailAPIObj.type = "common";
    } else if (
      location.hasOwnProperty("doordashLocationId") &&
      location.doordashLocationId && ! location.mixtLocationId
    ) {
      getMenuDetailAPIObj.locationId1 = parseInt(location.doordashLocationId);
    }
    else if( location.hasOwnProperty("mixtLocationId")  &&
    location.mixtLocationId && ! location.doordashLocationId )
    {
      getMenuDetailAPIObj.locationId1 = parseInt(location.mixtLocationId);
  }
  } 
  // else if (
  //   location.hasOwnProperty("doordashLocationId") &&
  //   location.doordashLocationId &&
  //   location.mixtLocationId
  // ) {
  //   getMenuDetailAPIObj.locationId2 = parseInt(location.doordashLocationId);
  //   getMenuDetailAPIObj.type = "common";
  // } 
  // else if (
  //   location.hasOwnProperty("doordashLocationId") &&
  //   location.doordashLocationId
  // ) {
  //   getMenuDetailAPIObj.locationId1 = parseInt(location.doordashLocationId);
  // }
  return getMenuDetailAPIObj;
};

const createIngredientsList = (orderItemsArray, menuItemDetail) => {
  let ingredientsList = menuItemDetail.map((singleItem, index) => {
    if (singleItem.hasOwnProperty("generalError")) {
      return {};
    } else {
      // POPULATE DEFAULT INGREDIENT
      let singleIngredientsList = populateDefaultList(
        singleItem.ingredients,
        singleItem.modifiers,
        singleItem.defaultProteinBrinkId,
        {},
        1
      );
      // GET SELECTED ORDER INGREDIENT LIST
      let previousSelectedIngredientsList =
        orderItemsArray[index].modifierItems;
      // REPOPULATE ALL SELECTED DATA
      singleIngredientsList = rePopulateIngredientsFromOrderData(
        previousSelectedIngredientsList,
        singleIngredientsList,
        singleItem.modifiers,
        singleItem.ingredients
      );

      singleIngredientsList = updateModifiersCounter(
        singleIngredientsList,
        singleItem.modifiers
      );
      return singleIngredientsList;
    }
  });

  return ingredientsList;
};

const getMenuItemsDetails = async (itemIds) => {
  let promisesList = [];
  itemIds.map(async (item) => {
    promisesList.push(GetMenuItemDetailForReorderAPI(item));
  });
  let result = await Promise.all(promisesList);
  return result;
};

const updateItemsPriceInJson = async (itemsToUpdate, locationForMenu) => {
  try {
    let brinkIds = [];
    itemsToUpdate.map((singleItem) => {
      brinkIds.push(singleItem.itemIdBrink);
    });
    let APIData = {
      itemBrinkIds: brinkIds,
      type: locationForMenu.type,
      locationId1: (locationForMenu.type === "single") 
      ? (locationForMenu.locationId1 || locationForMenu.locationId2) 
      : (locationForMenu.type === "common") 
      ? (locationForMenu.locationId1) 
      : undefined,
    };

    if ( locationForMenu.type === 'common' && locationForMenu.locationId2 && locationForMenu.locationId1 !== "") {
      APIData.locationId2 = parseInt(locationForMenu.locationId2);
    }
    if (locationForMenu.locationId2 && locationForMenu.locationId1 === "") {
      APIData.locationId1 = parseInt(locationForMenu.locationId2);
    }

    const result = await GetFavoriteItemDetail(APIData);
    if (result.error !== "") return { status: 0, data: null };
    else {
      let newItemsWithLatestPrices = [];
      let ApiItems = result.data.data;
      itemsToUpdate?.map((singleItemToUpdate) => {
        let isItemFound = false;
        ApiItems.map((singleApiItem) => {
          if (singleApiItem.itemIdBrink === singleItemToUpdate.itemIdBrink) {
            isItemFound = true;
            singleItemToUpdate.price = singleApiItem.price;
            let singleItemModifierItemsList = [];
            singleItemToUpdate.modifierItems.map((singleModifierItem) => {
              singleApiItem.modifiers.map((singleApiModifier) => {
                if (
                  singleApiModifier.brinkId ===
                    singleModifierItem.modifierGroupIdBrink ||
                  singleApiModifier.brinkIdProtein ===
                    singleModifierItem.modifierGroupIdBrink ||
                  singleApiModifier.brinkIdSubstitution ===
                    singleModifierItem.modifierGroupIdBrink
                ) {
                  singleApiModifier.modifierGroupItems.map(
                    (singleApiModifierGroupItem) => {
                      if (
                        singleApiModifierGroupItem.menuItem.itemIdBrink ===
                        singleModifierItem.itemIdBrink
                      ) {
                        let priceMethod = `priceMethod${
                          singleModifierItem.modifierOfType === 2
                            ? ""
                            : singleApiModifierGroupItem.hasOwnProperty(
                                "priceMethodProtein"
                              )
                            ? "Protein"
                            : ""
                        }`;
                        console.log(
                          "priceMethod",
                          priceMethod,
                          singleModifierItem.modifierOfType
                        );
                        singleModifierItem.itemPrice =
                          singleApiModifierGroupItem.menuItem.itemIdBrink ===
                          singleApiItem.defaultProteinBrinkId
                            ? 0
                            : singleApiModifierGroupItem[priceMethod] === 0
                            ? singleApiModifierGroupItem.menuItem.price
                            : singleApiModifierGroupItem.price;

                        singleItemModifierItemsList.push(singleModifierItem);
                      }
                    }
                  );
                }
              });
            });
            singleItemToUpdate.modifierItems = singleItemModifierItemsList;
          }
        });
        if (isItemFound) {
          newItemsWithLatestPrices.push(singleItemToUpdate);
        }
      });
      return { status: 1, data: itemsToUpdate };
    }
  } catch (error) {
    console.log(error, error.message);
    return { status: 0, data: null };
  }
};

const CreateNewOrderDataAPI = async (
  item,
  orderData,
  type,
  locationForMenu = null
) => {
  
  let orderItemsArray = [];
  let getMenuDetailAPIObj = createMenuRequestData(orderData, type);
  // GET FAVORITE ITEM OR REORDER OBJECT MENU ITEMS LIST
  if (type === "favorite") {
    if (Object.keys(locationForMenu).length === 0){
      if(orderData.mixtLocationId && !orderData.doordashLocationId)
      {
        locationForMenu={"locationId1":orderData.mixtLocationId,"type":"single"}

      }
      else if(!orderData.mixtLocationId && orderData.doordashLocationId)
        {
          locationForMenu={"locationId1":orderData.doordashLocationId,"type":"single"}

      }
      else if(orderData.mixtLocationId && orderData.doordashLocationId)
        {
          locationForMenu={"locationId1":orderData.mixtLocationId,"locationId2":orderData.doordashLocationId,"type":"common"}

        }
    }

    let favoriteItemDetail = await GetFavoriteItemDetailAPI(item);
    if (favoriteItemDetail.data.length === 0) {
      return { status: 0, message: "Favorite Item Not Found" };
    } else {
      let itemsJson = _.cloneDeep(favoriteItemDetail.data[0].itemJson);
      let itemsJsonResult = await updateItemsPriceInJson(
        [_.cloneDeep(itemsJson)],
        locationForMenu
      );
      if (!itemsJsonResult.status)
        return { status: 0, message: "Favorite Item Not Found" };
      else orderItemsArray.push(itemsJsonResult.data[0]);
    }
  } else {
    if (type === "reorder") {
      let itemsJsonResult = await updateItemsPriceInJson(
        item,
        getMenuDetailAPIObj
      );
      if (!itemsJsonResult.status)
        return { status: 0, message: "Order Item Not Found" };
      else orderItemsArray = itemsJsonResult.data;
    } else orderItemsArray = item;
  }

  // CREATE API DATA FOR EACH OBJECT TO GET MENU DETAIL
  let itemIds = orderItemsArray.map((orderItem) => {
    return { ...getMenuDetailAPIObj, id: orderItem.itemIdBrink };
  });
  let order = {};
  let menuItemsDetail = await getMenuItemsDetails(itemIds);
  menuItemsDetail.map((item) => {
    if (item.hasOwnProperty("generalError")) {
      order = { message: item.generalError };
    }
  });
  let ingredientsList = createIngredientsList(orderItemsArray, menuItemsDetail);
  let itemsList = [];
  ingredientsList.map((ingredientItem, index) => {
    if (Object.keys(ingredientItem).length > 0) {
      let totalPrice = calculateOrderPrice(
        ingredientItem,
        menuItemsDetail[index].price
      );
      // UPDATE PRICE ACCORDING TO QUANTITY
      totalPrice *= orderItemsArray[index].quantity;
      totalPrice = totalPrice.toFixed(2);
      itemsList.push(
        ...createOrderItemsData(
          ingredientItem,
          menuItemsDetail[index].ingredients,
          { name: menuItemsDetail[index].name.toUpperCase() },
          menuItemsDetail[index].modifiers,
          orderData.previousOrderItemsArray,
          {
            itemIdBrink: menuItemsDetail[index].itemIdBrink,
            name: ingredientItem.notes ? ingredientItem.notes : "",
            basicPrice: menuItemsDetail[index].price,
            orderQuantity: orderItemsArray[index].quantity,
            itemphoto: menuItemsDetail[index].itemphoto,
            isEdit: false,
            modifierMethodType: menuItemsDetail[index].modifierMethodType,
            categoryMenuItemIdBrink: menuItemsDetail[index]
              .categoryMenuItemIdBrink
              ? menuItemsDetail[index].categoryMenuItemIdBrink
              : 0,
            isSuggestionItem: false,
            categoryCustomType: menuItemsDetail[index].categoryCustomType,
            itemCustomType: menuItemsDetail[index].itemCustomType,
            isEdit: false,
            totalPrice: totalPrice,
          }
        )
      );
    }
  });
  order.items = itemsList;

  return order;
};

const CreateCartDetailAPI = (locationForMenu, selectedTime, orderData) => {
  let data = {
    discounts: orderData && orderData.discounts ? orderData.discounts : null,
    previousOrderId: "ppp",
    orderType: locationForMenu.orderType,
    mixtLocationId: locationForMenu.locationId1,
    orderDate: selectedTime,
    hasUtensils:
      orderData && orderData.hasUtensils ? orderData.hasUtensils : false,
  };

  if (
    locationForMenu.orderType === "delivery" &&
    locationForMenu.locationId2 !== ""
  ) {
    data.doordashLocationId = locationForMenu.locationId2;
  }

  if (locationForMenu.orderType === "delivery") {
    data.delivery_info = {
      locationName: locationForMenu.name,
      fullAddress: locationForMenu.locationAddress,
      companyName: locationForMenu.companyName,
      city: locationForMenu.city,
      zipCode: locationForMenu.zipCode,
      state: locationForMenu.state,
      latitude: locationForMenu.latitude,
      longitude: locationForMenu.longitude,
      instructions: locationForMenu.instructions,
      idUserDeliveryAddress: locationForMenu.idUserDeliveryAddress,
    };
  } else {
    data.pickup_location_info = {
      locationName: locationForMenu.name,
      fullAddress: locationForMenu.locationAddress,
    };
  }

  return data;
};

const CreateGoogleAnalyticsAPI = async (event, params) => {
  let profile = store.getState().profile;
  try {
    await analytics.logEvent(event, {
      ...params,
      user_id: profile.user_id,
      user_name: profile.first_name + " " + profile.last_name,
      email: profile.email,
    });
  } catch (error) {}
};

// GET FAVORITE ITEM DETAIL
const GetFavoriteItemDetail = async (data) => {
  // GET ALL PICKUP LOCATION
  const result = await sendPostCallToSever(
    data,
    MICRO_SERVICES.MENUS + "/menu/menu-items-for-prices"
  );
  return result;
};

export {
  FieldsValidation,
  convertUnixTimeStampDate,
  getBusinessAndCutOffTiming,
  getTimeAndClosedStatus,
  modifyPunchData,
  EncryptReduxAuth,
  DecryptReduxAuth,
  getImageUrl,
  CalculateTotalAmountOfOrder,
  GetOrderItemsListToSeparateArrays,
  updateCartRewards,
  CreateNewOrderDataAPI,
  CreateCartDetailAPI,
  CreateGoogleAnalyticsAPI,
  GetFavoriteItemDetail,
};
