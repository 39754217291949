import React, { useState } from "react";
import CloseCircle from "../../assets/img/CloseCircle.svg";
import { Button, Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { CircularProgress } from "@material-ui/core";
import { AddBarCodeAPI } from "../../actions/Auth/Auth";
import MessagesList from "../../config/messages.json";
import MessageDialog from "../MessageDialog";

function BarCode({ setAskForCode }) {
  // CREATE LOCAL STATE
  const [promoCode, setPromoCode] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successMessageHeading, setSuccessMessageHeading] = useState("");
  const [loader, setLoader] = useState("");

  const applyPromoCode = async () => {
    setIsSubmitted(true);
    setLoader(true);

    if (promoCode === "") {
      setErrorMessage("Enter Code");
    } else {
      let result = await AddBarCodeAPI(promoCode);
      if (result.generalError) setErrorMessage(result.generalError);
      else {
        let message = MessagesList["Bar Code Points Earned"];
        message = message.replace("{{POINTS}}", result.data.points_earned);
        setSuccessMessage(message);
        setSuccessMessageHeading(MessagesList["Bar Code Points Earned Title"]);
        setShowSuccessMessage(true);
      }
    }
    setLoader(false);
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {
          setAskForCode(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className={`customize_modal barcode_modal`}
      >
        <DialogContent>
          <div className="d-flex flex-column">
            <img
              src={CloseCircle}
              className={"close-confirm-reward cursor-pointer"}
              onClick={() => {
                setAskForCode(false);
              }}
            />
            <h3 className="BrandenGrotesqueBold font-size-1_7 text-grey4 text-center text-uppercase cmt-20">
              earn points
            </h3>
            <div className={`w-100 bg-yellow1 py-4 mt-3 mb-5`}>
              <p className="BrownSTDRegular font-size-0_9 text-grey4 text-center line-height-1_25 width-233 mx-auto">
                Enter the bar code on your receipt to earn points on your
                purchase
              </p>
            </div>

            <Form.Group className="w-70 m-auto text-center">
              <Form.Control
                className="payment_field"
                autoComplete={"false"}
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                  setErrorMessage("");
                  setIsSubmitted(false);
                }}
                isInvalid={isSubmitted && errorMessage !== ""}
                placeholder={"Enter Barcode"}
              />
              <Form.Control.Feedback type="invalid">
                {errorMessage}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="text-center">
              <Button
                variant=""
                className={`text-uppercase BrandenGrotesqueBold text-white border-0 font-size-1_2 bg-green3 rounded-full default-letter-spacing btn-half width-233 mt-3 mb-0`}
                onClick={applyPromoCode}
              >
                {loader ? <CircularProgress size={23} /> : "Submit"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <MessageDialog
        showError={showSuccessMessage}
        text={successMessage}
        closeDialog={setAskForCode}
        heading={successMessageHeading}
      />
    </>
  );
}

export default BarCode;
